import { useMyNotiList } from '@/hooks/apiHooks';
import Accordion from '@/components/Profile/SecondItem/AlarmSettings/Accordion';
import {
  Container,
  StyledLayout,
  StyledTitle,
} from '@/components/Profile/SecondItem/AlarmSettings/styles';
import { t } from 'i18next';

const AlarmSettings = () => {
  const { data } = useMyNotiList();

  return (
    <StyledLayout>
      <Container>
        {data?.map((res) => (
          <Accordion key={res.templateKey} {...res} />
        ))}
      </Container>
    </StyledLayout>
  );
};

export default AlarmSettings;
