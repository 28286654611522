import {
  Layout,
  StyledBox,
  StyledDate,
  StyledDetail,
  StyledDetailLayout,
  StyledLinkButton,
  StyledTitle,
  StyledTitleDetail,
  Container,
  StyledSubTitleDetail,
  StyledUsedDay,
  StyledUsedInfo,
  StyledUsedDate,
  StyledMonthLayout,
  DetailLayout,
  StyledLayout,
  StyledDetailSecond,
} from '@/components/Profile/SecondItem/Vacation/styles';
import { Divider } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-duotone-svg-icons';
import { useRecoilValue } from 'recoil';
import { MyInfoState } from '@/recoil/atom';
import { useGetDayOff } from '@/hooks/apiHooks';

import process from 'process';
import { t } from 'i18next';

const Vacation = () => {
  const { passportId } = useRecoilValue(MyInfoState);
  const year = new Date().getFullYear();

  const { data } = useGetDayOff(passportId, { year: year.toString() });

  const totalDays = data?.possibleLeaveDays ?? 0;

  const usedDays = data?.usedDays ?? 0;

  const conversionDate = (str: string) => {
    const date = new Date(str);
    return `${('00' + (date.getMonth() + 1).toString()).slice(-2)}월 ${(
      '00' + date.getDate().toString()
    ).slice(-2)}일`;
  };

  const sendPostMessage = () => {
    const sendData = JSON.stringify({
      actionType: 'MOVE_TAB',
      tabName: 'approval',
      url: `${process.env.NEXT_PUBLIC_IFRAME_URL}/intranet/approval/create?apv_type=32`,
    });
    window?.ReactNativeWebView?.postMessage(sendData);
  };

  const sendDetailPostMessage = (documentId: string) => {
    const sendData = JSON.stringify({
      actionType: 'MOVE_TAB',
      tabName: 'approval',
      url: `${process.env.NEXT_PUBLIC_IFRAME_URL}/intranet/approval/v?doc=${documentId}`,
    });
    window?.ReactNativeWebView?.postMessage(sendData);
  };

  const handleOpen = () => {
    if (window?.isRNWebview) {
      sendPostMessage();
    } else {
      window.open(
        'https://pop.fnf.co.kr/intranet/approval/create?apv_type=32',
        '_blank',
        'noreferrer',
      );
    }
  };

  const handleDetail = (documentId?: string) => {
    if (window?.isRNWebview) {
      documentId && sendDetailPostMessage(documentId);
    } else {
      documentId &&
        window.open(
          `${process.env.NEXT_PUBLIC_IFRAME_URL}/intranet/approval/v?doc=${documentId}`,
          '_blank',
          'noreferrer',
        );
    }
  };

  return (
    <Layout>
      <Container>
        <StyledBox>
          <div>{t('발급')}</div>
          <StyledDate>{totalDays}</StyledDate>
        </StyledBox>
        <div>-</div>
        <StyledBox>
          <div>{t('사용')}</div>
          <StyledDate>{usedDays}</StyledDate>
        </StyledBox>
        <div>=</div>
        <StyledBox>
          <div>{t('잔여')}</div>
          <StyledDate style={{ fontWeight: '600' }} activeColor>
            {data?.remainingDays}
          </StyledDate>{' '}
          {t('일')}
        </StyledBox>
      </Container>
      <StyledLinkButton onClick={handleOpen}>
        {t('연차 신청')}
        <FontAwesomeIcon icon={faArrowUpRightFromSquare} color={'white'} />
      </StyledLinkButton>
      <StyledDetailLayout>
        <StyledTitleDetail>{t('휴가 상세')}</StyledTitleDetail>
        <StyledSubTitleDetail>
          {year}년 휴가 사용내역입니다.
        </StyledSubTitleDetail>
        <DetailLayout>
          {data?.annualLeaves.map((useData) => (
            <>
              <StyledDetail>
                <StyledUsedDay
                  onClick={() => handleDetail(useData.documentId)}
                  link={!!useData.documentId}
                >
                  {useData.startDate === useData.endDate
                    ? conversionDate(useData.startDate)
                    : `${conversionDate(useData.startDate)} ~ ${conversionDate(
                        useData.endDate,
                      )}`}
                </StyledUsedDay>
                <StyledUsedInfo>{t('사용')}</StyledUsedInfo>
                <StyledUsedDate>{`-${useData.days}${t('일')}`}</StyledUsedDate>
              </StyledDetail>
              <Divider />
            </>
          ))}
        </DetailLayout>
        {data && data.overages > 0 && (
          <>
            <StyledDetail style={{ color: 'red' }}>
              <StyledUsedDay>01월 01일</StyledUsedDay>
              <StyledUsedInfo>{t('전년초과사용')}</StyledUsedInfo>
              <StyledUsedDate>-{data?.overages}일</StyledUsedDate>
            </StyledDetail>
            <Divider />
          </>
        )}
        <StyledDetail>
          <StyledUsedDay>01월 01일</StyledUsedDay>
          <StyledUsedInfo>{t('발급')}</StyledUsedInfo>
          <StyledUsedDate>+{data?.possibleLeaveDays}일</StyledUsedDate>
        </StyledDetail>
        <Divider />
        <StyledLayout>
          {data && data?.totalMonthlyLeaveDays > 0 && (
            <StyledDetailSecond>
              <StyledUsedDay>{t('올해 월차')}</StyledUsedDay>
              <StyledUsedDate>+{data?.totalMonthlyLeaveDays}일</StyledUsedDate>
            </StyledDetailSecond>
          )}
          {data && data?.totalAnnualLeaveDays > 0 && (
            <StyledDetailSecond>
              <StyledUsedDay>{t('올해 연차')}</StyledUsedDay>
              <StyledUsedDate>+{data?.totalAnnualLeaveDays}일</StyledUsedDate>
            </StyledDetailSecond>
          )}
          {data && data?.unusedMonthlyLeaveDays > 0 && (
            <StyledDetailSecond>
              <StyledUsedDay>{t('전년 월차 정산')}</StyledUsedDay>
              <StyledUsedDate>+{data?.unusedMonthlyLeaveDays}일</StyledUsedDate>
            </StyledDetailSecond>
          )}
        </StyledLayout>
        <Divider />
      </StyledDetailLayout>
    </Layout>
  );
};
export default Vacation;
