import React, { Fragment } from 'react';
import { useChangePassport, usePassport } from '@/hooks/apiHooks';
import { useRouter } from 'next/router';
import ComboBox from '@/components/ComboBox';
import { t } from 'i18next';
import { RnMessage, sendToRN } from '@/utils/function';

type P = {
  mobileMode?: boolean;
};

export default function ChangeUserPassport({ mobileMode }: P) {
  const { data } = usePassport();

  const router = useRouter();

  const handleMenuItemClick = (passportId: string) => {
    if (confirm(t('프로필을 전환하면 앱이 다시 실행됩니다.'))) {
      useChangePassport(passportId).then((_) => {
        window.isRNWebview ? sendToRN(RnMessage.RESTART) : router.reload();
      });
    }
  };

  return (data?.assignedPassports.length || 0) > 1 ? (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: mobileMode ? '24px 20px' : '',
        width: '100%',
      }}
    >
      <ComboBox
        title={data?.defaultPassport.detail.companyName || ''}
        defaultKey={data?.defaultPassport.passportId || ''}
        dataList={
          data
            ? data?.assignedPassports.map((res) => {
                return {
                  key: res.passportId,
                  profileImageUrl: res.detail.profileImageUrl,
                  title: res.detail.companyName,
                  subtitle: res.detail.departmentName,
                };
              })
            : []
        }
        handleMenuItemClick={handleMenuItemClick}
      />
    </div>
  ) : (
    <Fragment />
  );
}
