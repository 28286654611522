import React, { ReactNode } from 'react';
import {
  Container,
  StyledBack,
  StyledHeader,
} from '@/components/Profile/SecondItem/Header/styles';
import SvgImage from '@/components/Module/SvgImage';
import { t } from 'i18next';

type P = {
  children: ReactNode;
  onClose: () => void;
  width?: string;
  title?: string;
};
const Header = ({ children, onClose, width, title }: P) => {
  return (
    <Container width={width}>
      <StyledHeader>
        <StyledBack onClick={onClose}>
          <SvgImage name={'back'} />
          {title && t(title)}
        </StyledBack>
      </StyledHeader>
      {children}
    </Container>
  );
};

export default Header;
