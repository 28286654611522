import styled from '@emotion/styled';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 2rem;
`;

const StyledTitle = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
`;

const StyledContent = styled.div`
  display: flex;
  padding: 1rem 2rem;
  color: ${({ theme }) => theme.colors.primary};
  background-color: rgba(225, 240, 255, 1);
  border-radius: 0.42rem;
`;

const RowLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-bottom: 3rem;
`;

const StyledSubmitButton = styled.button`
  margin-top: 2rem;
  width: 100%;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.dark};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 0.25rem;
  transition: opacity 0.2s ease-in-out;
  :disabled {
    opacity: 0.1;
  }
`;

const StyledSubTitle = styled.div`
  font-size: 1rem;
  padding-bottom: 1rem;
  > span {
    color: red;
  }
`;

export {
  RowLayout,
  Layout,
  StyledContent,
  StyledTitle,
  StyledSubmitButton,
  StyledSubTitle,
};
