import {
  Layout,
  StyledContent,
} from '@/components/Profile/SecondItem/Language/styles';
import { useChangeLanguage, usePassport } from '@/hooks/apiHooks';
import ComboBox from '@/components/ComboBox';
import React from 'react';
import { useRouter } from 'next/router';
import { Language } from '@/types/api';
import { RnMessage, sendToRN } from '@/utils/function';
import { t } from 'i18next';

const languageList: { key: Language }[] = [
  {
    key: 'ko',
  },
  { key: 'en' },
  { key: 'zh' },
];

const Language = () => {
  const { data } = usePassport();
  const defaultLanguage = data?.personalization.lang || 'default';
  const router = useRouter();

  const handleMenuItemClick = (language: string) => {
    if (confirm('새로고침하여 언어가 전환됩니다.')) {
      useChangeLanguage(language).then((_) => {
        window.isRNWebview ? sendToRN(RnMessage.RESTART) : router.reload();
      });
    }
  };

  return (
    <Layout>
      <StyledContent>
        {t('언어를 바꾸면 포탈이 다시 실행됩니다.')}
      </StyledContent>
      <ComboBox
        title={codeToWord(defaultLanguage)}
        defaultKey={defaultLanguage === 'default' ? 'ko' : defaultLanguage}
        dataList={languageList.map((lang) => {
          return {
            key: lang.key,
            title: codeToWord(lang.key),
          };
        })}
        handleMenuItemClick={handleMenuItemClick}
      />
    </Layout>
  );
};

export default Language;

export const codeToWord = (code: string) => {
  switch (code) {
    case 'ko':
    case 'default':
      return '한국어';
    case 'en':
      return 'English';
    case 'zh':
      return '中文';
    default:
      return '';
  }
};
