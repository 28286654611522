import styled from '@emotion/styled';

const Layout = styled.div`
  padding: 2rem;
  font-family: var(--font-Pretendard);
`;

const StyledTitle = styled.div`
  font-size: 1.25rem;
  font-weight: 600;
`;

const StyledSubContent = styled.div`
  margin-top: 1rem;
  padding: 1rem 2rem;
  color: #8a8f97;
  background-color: #f3f5f6;
  border-radius: 0.42rem;
  > span {
    text-decoration: underline;
    font-weight: 600;
  }
`;

const LinkLayout = styled.a`
  display: flex;
  text-align: right;
  margin-top: 0.5rem;
  align-items: center;
  justify-content: right;
  gap: 0.5rem;
`;

const StyledLink = styled.div`
  text-decoration: underline;
`;

LinkLayout.defaultProps = {
  target: '_blank',
  rel: 'noreferrer',
  href: 'https://pop.fnf.co.kr/bbs/wiki_view?n=7100&d=24&c=CI',
};

const StyledBox = styled.div`
  padding: 0 2rem 2rem 2rem;
  text-align: center;
`;

const StyledDate = styled.span<{ activeColor?: boolean }>`
  font-size: 1.5rem;
  color: ${({ theme, activeColor }) =>
    activeColor ? theme.colors.primary : theme.colors.textPrimary};
`;

const StyledDetailLayout = styled.div`
  margin-top: 3rem;
`;

const StyledDetail = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem;
  justify-content: space-between;
`;

const StyledTitleDetail = styled.div`
  font-size: 1.25rem;
  font-weight: 500;
  margin-top: 2rem;
`;

const StyledSubTitleDetail = styled.div`
  color: #90959e;
  font-size: 0.9rem;
`;

const StyledLinkButton = styled.button`
  border-radius: 0.42rem;
  border: 1px solid gainsboro;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  gap: 0.5rem;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.dark};
  color: ${({ theme }) => theme.colors.white};
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const StyledUsedDay = styled.button<{ link?: boolean }>`
  color: #8a8f97;
  width: 60%;
  text-decoration: ${({ link }) => (link ? 'underline' : 'none')};
  text-align: start;
  padding: 0;
`;

const StyledUsedInfo = styled.div`
  width: 20%;
  text-align: center;
`;
const StyledUsedDate = styled.div`
  width: 20%;
  text-align: right;
`;

const StyledMonthLayout = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  color: #8a8f97;
  background-color: #f3f5f6;
  border-radius: 0.42rem;
  display: flex;
  justify-content: space-between;
`;

const DetailLayout = styled.div`
  margin-top: 0.5rem;
`;

const StyledLayout = styled.div`
  color: var(--text-primary-color);
  background-color: var(--button-background-color);
  border-radius: 0.42rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
const StyledDetailSecond = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export {
  Layout,
  LinkLayout,
  StyledBox,
  StyledDate,
  StyledDetail,
  StyledDetailLayout,
  StyledLink,
  StyledLinkButton,
  StyledSubContent,
  StyledTitle,
  StyledTitleDetail,
  Container,
  StyledSubTitleDetail,
  StyledUsedDay,
  StyledUsedInfo,
  StyledUsedDate,
  StyledMonthLayout,
  DetailLayout,
  StyledLayout,
  StyledDetailSecond,
};
