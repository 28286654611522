import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-duotone-svg-icons';
import {
  Layout,
  StyledTitle,
  StyledSubContent,
  StyledLinkButton,
  StyledSubTitle,
  StyledLayout,
  StyledSecondSubContent,
  StyledMiniTitle,
} from '@/components/Profile/SecondItem/Salary/styles';
import { useRecoilValue } from 'recoil';
import { MyInfoState } from '@/recoil/atom';

const Salary = () => {
  const { employeeNumber, phoneMobile } = useRecoilValue(MyInfoState);

  return (
    <Layout>
      iPayView를 통해 급여명세서 조회 및 연말정산이 가능합니다.
      <br />
      *2019년 12월 이전 급여명세서 조회: HR팀 문의
      <StyledSubTitle>iPayView 로그인 정보</StyledSubTitle>
      <StyledMiniTitle>대상 회사코드</StyledMiniTitle>
      <StyledSubContent>
        <ul>
          <li>F&F: Q623</li>
          <li>F&F 홀딩스: Q526</li>
          <li>F&F 로지스틱스: Q739</li>
          <li>F&F 파트너스: Q597</li>
          <li>F&F 엔터테인먼트: Q734</li>
        </ul>
      </StyledSubContent>
      <StyledLayout>
        <StyledMiniTitle>사원번호</StyledMiniTitle>
        <div>{employeeNumber || '포탈 마이페이지에서 확인'}</div>
      </StyledLayout>
      <StyledLayout>
        <StyledMiniTitle>휴대폰</StyledMiniTitle>
        <div>
          <div>{phoneMobile}</div>
          <StyledSecondSubContent>
            포탈 인사정보에 등록된 휴대폰 번호
          </StyledSecondSubContent>
        </div>
      </StyledLayout>
      <StyledLayout>
        <StyledMiniTitle>비밀번호</StyledMiniTitle>
        <div>iPayView에서 설정한 비밀번호</div>
      </StyledLayout>
      <StyledLinkButton>
        iPayView 열기
        <FontAwesomeIcon icon={faArrowUpRightFromSquare} color={'white'} />
      </StyledLinkButton>
    </Layout>
  );
};
export default Salary;
