import {
  StyledContainer,
  Layout,
  StyledButton,
  StyledLink,
  StyledForm,
  StyledInput,
  StyledInputLayout,
  StyledSubmit,
  StyledCancel,
} from '@/components/Profile/SecondItem/PersonalInfo/styles';
import InfoItem from '@/components/Profile/SecondItem/PersonalInfo/InfoItem';
import { useRecoilState } from 'recoil';
import { MyInfoState } from '@/recoil/atom';
import { useSearchPoint, useUpdateNickName } from '@/hooks/apiHooks';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { NickName } from '@/components/Profile/SecondItem/MyInfo';
import SVG from '@/components/SVG/SVG';
import { useTranslation } from 'react-i18next';
import SvgImage from '@/components/Module/SvgImage';

const PersonalInfo = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [myInfo, setMyInfo] = useRecoilState(MyInfoState);

  const {
    name,
    nickname,
    companyName,
    organizationFullName,
    organizationShortName,
    positionName,
    employeeNumber,
    email,
    phoneInside,
    phoneMobile,
    enterDate,
    language,
  } = myInfo;
  const korean = language === 'ko';
  const { data } = useSearchPoint(employeeNumber);

  const { register, watch, handleSubmit } = useForm<NickName>({
    defaultValues: { nickname: myInfo.nickname },
  });

  const toggle = (fn: Dispatch<SetStateAction<boolean>>) => () =>
    fn((prev) => !prev);

  const onSubmit: SubmitHandler<NickName> = (data) =>
    useUpdateNickName(data)
      .then((t) => {
        setMyInfo({ ...myInfo, nickname: data.nickname });
        setOpen(false);
      })
      .catch((e) => {
        if (e.response?.data?.message) alert(e.response?.data?.message);
        else alert(e);
      });

  const sendPostMessage = () => {
    const sendData = JSON.stringify({
      actionType: 'MOVE_TAB',
      tabName: 'approval',
      url: `${process.env.NEXT_PUBLIC_IFRAME_URL}/intranet/approval/create?apv_type=54`,
    });
    window?.ReactNativeWebView?.postMessage(sendData);
  };

  const handleOpen = () => {
    if (window?.isRNWebview) {
      sendPostMessage();
    } else {
      window.open(
        'https://i.fnf.co.kr/intranet/approval/create?apv_type=54',
        '_blank',
        'noreferrer',
      );
    }
  };

  return (
    <Layout>
      <StyledContainer>
        인사 정보는 HR팀을 통해서만 등록 및 수정이 가능합니다.
      </StyledContainer>
      <InfoItem title={korean ? '이름' : 'Name'} content={name} />
      <InfoItem
        title={korean ? '영어 이름' : 'English Name'}
        content={nickname}
        style={{ position: 'relative' }}
      >
        <StyledButton onClick={toggle(setOpen)}>
          <SVG
            name="edit"
            width={24}
            height={24}
            viewBox="0 0 24 24"
            className="svg-icon"
          />
          수정
        </StyledButton>
        <StyledInputLayout open={open}>
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <StyledInput {...register('nickname')} disableUnderline />
            <StyledCancel onClick={toggle(setOpen)}>{t('취소')}</StyledCancel>
            <StyledSubmit>저장</StyledSubmit>
          </StyledForm>
        </StyledInputLayout>
      </InfoItem>
      <InfoItem
        title={korean ? '사번' : 'Employee Number'}
        content={employeeNumber}
      />
      {enterDate && (
        <InfoItem
          title={korean ? '입사일' : 'Employment Date'}
          content={enterDate}
        />
      )}
      <Divider />
      <InfoItem title={korean ? '법인' : 'Company'} content={companyName} />
      <InfoItem
        title={korean ? '소속' : 'Department'}
        content={organizationFullName}
      />
      <InfoItem
        title={korean ? '소속 약어' : 'Department Code'}
        content={organizationShortName}
      />
      <InfoItem title={korean ? '직급' : 'Position'} content={positionName} />
      <Divider />
      <InfoItem title={'E-mail'} content={email} />
      <InfoItem
        title={korean ? '내선전화' : 'Extension Number'}
        content={phoneInside}
      />
      <InfoItem
        title={korean ? '휴대폰 번호' : 'Mobile'}
        content={phoneMobile}
      />
      <Divider />
      <InfoItem
        title={korean ? '직원 구매 포인트' : 'Point'}
        content={`${data?.employeePoint.toLocaleString()} 원`}
      >
        <StyledLink onClick={handleOpen}>
          <SvgImage name={'plus'} />
          추가 요청
        </StyledLink>
      </InfoItem>
    </Layout>
  );
};

export default PersonalInfo;

const Divider = () => (
  <div
    style={{
      width: '100%',
      backgroundColor: '#E7E9EC',
      height: '1px',
      marginTop: '18px',
    }}
  />
);
