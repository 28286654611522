import styled from '@emotion/styled';

const Container = styled.div<{ width?: string }>`
  width: ${({ width }) => (width ? width : '400px')};
  display: flex;
  flex-direction: column;
`;

const StyledHeader = styled.div`
  background-color: white;
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  position: sticky;
  top: 0;
  height: 57px;
  align-items: center;
  z-index: 1;
`;

const StyledBack = styled.button`
  padding: 1rem;
  display: flex;
  align-items: center;

  color: black;
  gap: 1rem;
  height: 65px;
  font-weight: 400;
  font-size: 16px;
`;

StyledBack.defaultProps = {
  type: 'button',
};
const Layout = styled.div``;

export { StyledHeader, Layout, StyledBack, Container };
